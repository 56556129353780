import React, { useEffect, useState, useCallback } from 'react';
import "./booking-details.css";
import Compressor from 'compressorjs';
import DateFnsUtils from '@date-io/date-fns';
import { toast } from 'react-toastify';
import {
    DatePicker,
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
    getEvents,
    addEvent,
    uploadEventImage,
    updateEvent,
    removeEvent,
    getEventParticipants,
    joinEventCall
} from "../../crud/service.crud";
import {
    getProfile
} from "../../crud/profile.crud";
import RemoveModal from "./remove-modal";
import JoinEventCallConfirmationModal from "./join-event-confirmation-modal";
import { paytmUrl } from "../../crud/api.util";
import { REQUIRED_ERROR, INVALID_FROM_TO_DATE, API_ERROR, CALL_TIME_PASSED } from "../../config/error-message";
import { API_SUCCESS } from "../../config/success-message";
import {
    timeArrConst,
    SessionConfig,
    SessionConfigNumber,
    VendorConfigStatus,
    compressionPercentage,
    compressionWidth,
    maxImageSize,
    monthArr
} from "../../config/constants";
import ParticipantsModal from './participants';
import ReadMoreModal from './read-more.modal';

const timeArr = [...timeArrConst];

const initialServiceDetails = {
    title: "",
    fees: "",
    description: "",
    noOfSeats: ""
};

const initialServiceError = {
    title: "",
    fees: "",
    description: "",
    noOfSeats: ""
};

const initialOrgServiceError = {
    days: "",
    selectedFromDate: ""
};
const today = new Date();
const yesterday = new Date(today);
const initialSessionDate = new Date(yesterday.setDate(yesterday.getDate() + 2));
// const serviceTimeHours = [...serviceTimeHoursConstant];

const Service = (props) => {
    const [sessionsList, updateUpcomingSessions] = useState([]);
    const [pastSessionsList, updatePastSessions] = useState([]);
    const [selectedFromDate, handleFromDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
    const [selectedToDate, handleToDateChange] = useState(new Date(new Date(new Date(new Date().setHours(1)).setMinutes(0)).setSeconds(0)));
    const [displayRemoveModal, openRemoveModal] = useState(false);
    const [displayConfirmationModal, openConfirmationModal] = useState(false);
    const [removeType, updateRemoveType] = useState("");
    const [serviceDetails, updateServiceDetails] = useState({ ...initialServiceDetails });
    const [serviceDetailsError, updateServiceErrorDetails] = useState({ ...initialServiceError });
    // const [selectedServiceTime, updateSelectedServiceTime] = useState(serviceTimeHours[0].value);

    const [orgServiceDetailsError, updateOrgServiceDetailsError] = useState({ ...initialOrgServiceError });
    const [mode, changeMode] = useState("add");
    const [selectedServiceIdToRemove, updateServiceId] = useState("");
    const [disablePage, toggleDisablePage] = useState(false);
    const [displayMode, changeDisplayMode] = useState("list");
    const [selectedDate, handleDateChange] = useState(initialSessionDate);
    const [currentTab, updateCurrentTab] = useState("upcoming");
    const [showPrevButtons, togglePrevButton] = useState(false);
    const [showNextButtons, toggleNextButton] = useState(true);
    const [uploadedGalleryImage, updateGalleryImage] = useState(null);
    const [descriptionImages, updateDescriptionImages] = useState([]);
    const [selectedImageIndexToRemove, updateImageIndex] = useState(-1);
    const [selectedEvent, updateSelectedEvent] = useState({});
    const [selectedProfile, selectProfile] = useState({});
    const [openParticipantModal, toggleParticipantModal] = useState(false);
    const [participants, updateParticipants] = useState([]);
    const [openReadMoreModal, toggleReadMoreModal] = useState(false);
    const [readMoreDesc, updateReadMoreDesc] = useState("");

    const handleNextPrevButtons = () => {
        if (document.getElementsByClassName("categories-wrapper")[0]) {
            if (document.getElementsByClassName("categories-wrapper")[0].clientWidth < document.getElementsByClassName("categories-wrapper")[0].scrollWidth) {
                togglePrevButton(true);
                toggleNextButton(true);

                if (document.getElementsByClassName("categories-wrapper")[0].scrollLeft === 0) {
                    togglePrevButton(false);
                }
                if (Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollWidth - document.getElementsByClassName("categories-wrapper")[0].clientWidth) === Math.round(document.getElementsByClassName("categories-wrapper")[0].scrollLeft)) {
                    toggleNextButton(false);
                }

            } else {
                togglePrevButton(false);
                toggleNextButton(false);
            }
        }
    };

    const sideScroll = (element, direction, speed, distance, step) => {
        let scrollAmount = 0;
        let slideTimer = setInterval(function () {
            if (direction === 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                handleNextPrevButtons();
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    const nextClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'right', 25, 100, 10);
    };

    const prevClick = () => {
        var container = document.getElementsByClassName('categories-wrapper')[0];
        sideScroll(container, 'left', 25, 100, 10);
    };

    window.onresize = () => {
        handleNextPrevButtons();
    };

    window.onload = () => {
        handleNextPrevButtons();
    };

    const displayNavs = useCallback((bool) => {
        if (bool) {
            handleNextPrevButtons();
        }
    }, []);

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const getDisplayedFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const year = date.getFullYear();
        return day + ' ' + monthArr[date.getMonth()] + ', ' + year;
    };

    const getDuration = (times) => {
        times.sort((a, b) => { return a - b });
        const startTime = timeArr[times[0] - 1];
        const endTime = timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
        return `${startTime} - ${endTime}`;
    };

    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    const getTime = (timeStr, date) => {
        var time = timeStr.slice(0, timeStr.length - 3)
        var amPm = timeStr.slice(timeStr.length - 2)
        var d = new Date()
        if (date) {
            d = new Date(date);
        }
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1]))
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1]))
        }
        return new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1]))
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return getTime(timeArr[times[0] - 1])
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return getTime(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0])
    };

    const checkCurrentDay = (date) => {
        const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        // console.log("today ", today, day)
        return today === day;
    };

    const checkJoiningCallTime = useCallback((localBookingData) => {
        if (checkCurrentDay(localBookingData.date)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 1]);
            const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
            console.log("start-------> ", startTime, endTime, today)
            return (startTime <= today && endTime > today) && localBookingData.status === SessionConfigNumber.Confirmed;
        }
        return false;
    }, []);

    const checkEditable = useCallback((localBookingData) => {
        console.log("checkCurrentDay(localBookingData.date) ", localBookingData)
        // if (checkCurrentDay(localBookingData.date)) {
        return localBookingData.status === SessionConfigNumber.ApprovalPending;
        // }
        // return true;
    }, []);

    const checkCancelable = useCallback((localBookingData) => {
        if (checkCurrentDay(localBookingData.date)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 1]);
            return (startTime > today);
        }
        return true;
    }, []);

    const getProfileDetails = async () => {
        toggleDisablePage(true);
        const profileData = await getProfile();
        toggleDisablePage(false);
        if (profileData.data.errorCode) {
            toast.error(API_ERROR[profileData.data.errorCode]);
            return;
        }
        if (profileData.data.data) {
            const localSelectedProfile = {};

            if (profileData.data.data.accountStatus === VendorConfigStatus.approved &&
                !profileData.data.data.emailVerified) {
                toast.error("Please verify your email");
                props.history.push('/dashboard');
                return;
            }
            if (profileData.data.data.accountStatus !== VendorConfigStatus.approved) {
                props.history.push('/onboarding');
            }
            localSelectedProfile.businessId = profileData.data.data.businessId ? profileData.data.data.businessId : "";

            selectProfile({ ...localSelectedProfile });

        }
    };

    const getAllUpcomingServices = useCallback(async () => {
        toggleDisablePage(true);
        const servicesResult = await getEvents("upcoming");
        toggleDisablePage(false);
        if (servicesResult.data.errorCode) {
            toast.error(API_ERROR[servicesResult.data.errorCode]);
            return;
        }
        console.log("servicesResult ", servicesResult);
        const localServices = [];
        if (servicesResult.data.data && servicesResult.data.data.data && servicesResult.data.data.data.length) {
            let localSingleService = {};
            servicesResult.data.data.data.forEach(each => {
                localSingleService = { ...each };
                localSingleService.selected = false;
                localSingleService.fee = each.eventFees;
                localSingleService.status = each.status;
                localSingleService.isCallJoinable = checkJoiningCallTime(localSingleService);
                localSingleService.isEditable = checkEditable(localSingleService);
                localSingleService.isCancalable = checkCancelable(localSingleService);
                localSingleService.eventLink = each.eventLink;
                localServices.push({ ...localSingleService });
                localSingleService = {};
            });
        }
        updateUpcomingSessions([...localServices]);
    }, [checkJoiningCallTime, checkEditable, checkCancelable]);

    const getAllPastServices = async () => {
        toggleDisablePage(true);
        const servicesResult = await getEvents("past");
        toggleDisablePage(false);
        if (servicesResult.data.errorCode) {
            toast.error(API_ERROR[servicesResult.data.errorCode]);
            return;
        }
        console.log("servicesResult ", servicesResult);
        const localServices = [];
        if (servicesResult.data.data && servicesResult.data.data.data && servicesResult.data.data.data.length) {
            let localSingleService = {};
            servicesResult.data.data.data.forEach(each => {
                localSingleService = { ...each };
                localSingleService.fee = each.eventFees;
                localSingleService.status = each.status;
                localSingleService.refundAmount = each.paymentDetails && each.paymentDetails.refundAmount ? each.paymentDetails.refundAmount : 0;
                localServices.push({ ...localSingleService });
                localSingleService = {};
            });
        }
        updatePastSessions([...localServices]);
    };

    useEffect(() => {
        getAllUpcomingServices();
        getAllPastServices();
        getProfileDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllUpcomingServices]);

    const changeServiceDetails = (text, fieldName) => {
        const localService = { ...serviceDetails };
        const localServiceError = { ...serviceDetailsError };
        localService[fieldName] = text;
        localServiceError[fieldName] = "";
        updateServiceDetails({ ...localService });
        updateServiceErrorDetails({ ...localServiceError });
    };

    const validateService = () => {
        let noError = true;
        const localServiceError = { ...serviceDetailsError };
        const localOrgServiceError = { ...orgServiceDetailsError };

        if (new Date(selectedFromDate) > new Date(selectedToDate)) {
            localOrgServiceError.selectedFromDate = INVALID_FROM_TO_DATE;
            noError = false;
        } else {
            localOrgServiceError.selectedFromDate = "";
        }

        updateOrgServiceDetailsError({ ...localOrgServiceError });
        for (let item in localServiceError) {
            if (!serviceDetails[item].toString().trim().length) {
                localServiceError[item] = REQUIRED_ERROR;
                noError = false;
            } else {
                localServiceError[item] = "";
            }
        }
        updateServiceErrorDetails({ ...localServiceError });
        return noError;
    };

    const resetServiceForm = () => {
        changeMode("add");
        updateServiceDetails({ ...initialServiceDetails });
        updateServiceErrorDetails({ ...initialServiceError });
        // updateSelectedServiceTime(serviceTimeHours[0].value);

        updateOrgServiceDetailsError({ ...initialOrgServiceError });
        handleFromDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
        handleToDateChange(new Date(new Date(new Date(new Date().setHours(1)).setMinutes(0)).setSeconds(0)));

        updateDescriptionImages([]);
        if (document.getElementById("galleryImage")) {
            document.getElementById("galleryImage").value = "";
        }
        updateGalleryImage(null);

        handleDateChange(initialSessionDate);

        getAllUpcomingServices();
        if (window.innerWidth <= 768) {
            changeDisplayMode('list');
        }
    };

    const payNow = async () => {

        let requestBody = {};

        if (!validateService()) {
            return;
        }
        if (Number(serviceDetails.fees) === 0) {
            toast.error("Price cannot be zero");
            return;
        }
        if (Number(serviceDetails.noOfSeats) === 0) {
            toast.error("Seats cannot be zero");
            return;
        }
        if (Number(serviceDetails.noOfSeats) > 100) {
            toast.error("Seats cannot be more than 100");
            return;
        }
        if (!descriptionImages.length) {
            toast.error("Please upload atleast one image");
            return;
        }
        const fromIndex = (timeArr.indexOf(formatAMPM(selectedFromDate)) + 1);
        const toIndex = (timeArr.indexOf(formatAMPM(selectedToDate)));
        const localWorkingTimeSlots = [];

        if (fromIndex === toIndex) {
            localWorkingTimeSlots.push(fromIndex);
        } else {
            for (let i = fromIndex; i <= toIndex; i++) {
                localWorkingTimeSlots.push(i);
            }
        }
        if (!localWorkingTimeSlots.length) {
            toast.error("Please choose correct start and end time");
            return;
        }
        if (localWorkingTimeSlots.length < 2) {
            toast.error("Please choose duration of minimum 1 hour");
            return;
        }
        const uploadedImages = [];
        descriptionImages.forEach((each, ind) => {
            uploadedImages.push({
                url: each,
                order: ind + 1
            });
        });

        requestBody = {
            "title": serviceDetails.title,
            "description": serviceDetails.description,
            "fees": Number(serviceDetails.fees),
            "slots": localWorkingTimeSlots,
            "numberOfSeats": Number(serviceDetails.noOfSeats),
            "date": new Date(new Date(new Date(new Date(new Date(getFullDateFormat(new Date(selectedDate))).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString(),
            "images": uploadedImages
        };

        // console.log("requestBody ",requestBody)

        toggleDisablePage(true);
        const bookSlotResult = await addEvent(requestBody);
        console.log("bookSlotResult ", bookSlotResult);
        toggleDisablePage(false);
        if (bookSlotResult.data.errorCode) {
            toast.error(API_ERROR[bookSlotResult.data.errorCode]);
            return;
        }
        if (bookSlotResult.data.data && bookSlotResult.data.data.errorCode) {
            toast.error(API_ERROR[bookSlotResult.data.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.ADD_EVENT);
        resetServiceForm();
        getAllUpcomingServices();
    };

    const saveService = async () => {
        if (!validateService()) {
            return;
        }
        if (Number(serviceDetails.fees) === 0) {
            toast.error("Price cannot be zero");
            return;
        }
        if (Number(serviceDetails.noOfSeats) === 0) {
            toast.error("Seats cannot be zero");
            return;
        }
        if (Number(serviceDetails.noOfSeats) > 100) {
            toast.error("Seats cannot be more than 100");
            return;
        }
        if (!descriptionImages.length) {
            toast.error("Please upload atleast one image");
            return;
        }
        let requestBody = {};
        let result;

        const fromIndex = (timeArr.indexOf(formatAMPM(selectedFromDate)) + 1);
        const toIndex = (timeArr.indexOf(formatAMPM(selectedToDate)));
        const localWorkingTimeSlots = [];

        if (fromIndex === toIndex) {
            localWorkingTimeSlots.push(fromIndex);
        } else {
            for (let i = fromIndex; i <= toIndex; i++) {
                localWorkingTimeSlots.push(i);
            }
        }
        if (!localWorkingTimeSlots.length) {
            toast.error("Please choose correct start and end time");
            return;
        }
        const uploadedImages = [];
        descriptionImages.forEach((each, ind) => {
            uploadedImages.push({
                url: each,
                order: ind + 1
            });
        });
        requestBody = {
            "title": serviceDetails.title,
            "description": serviceDetails.description,
            "fees": Number(serviceDetails.fees),
            "slots": localWorkingTimeSlots,
            "numberOfSeats": Number(serviceDetails.noOfSeats),
            "date": new Date(new Date(new Date(new Date(new Date(getFullDateFormat(new Date(selectedDate))).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString(),
            "images": uploadedImages
        };

        console.log("requestBody ", requestBody);
        // return;

        if (mode === "add") {
            const myScript = document.createElement("script");
            myScript.setAttribute("src", paytmUrl);
            myScript.setAttribute("crossorigin", "anonymous");
            myScript.setAttribute("type", "application/javascript");
            document.body.appendChild(myScript);


            // result = await addEvent(requestBody);
        } else {
            toggleDisablePage(true);
            requestBody._id = serviceDetails._id;
            result = await updateEvent(requestBody);

            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }

            toast.success(API_SUCCESS.UPDATE_EVENT);
            resetServiceForm();
        }
    };

    const updateDateError = () => {
        const localOrgServiceError = { ...orgServiceDetailsError };
        localOrgServiceError.selectedFromDate = "";
        updateOrgServiceDetailsError({ ...localOrgServiceError });
    };

    const chooseService = (serviceData) => {
        const localServiceDetails = { ...serviceDetails };
        console.log("serviceData ", serviceData);
        localServiceDetails.title = serviceData.title;
        localServiceDetails.description = serviceData.description;
        localServiceDetails._id = serviceData._id;
        localServiceDetails.noOfSeats = serviceData.numberOfSeats;
        localServiceDetails.oldNoOfSeats = serviceData.numberOfSeats;
        localServiceDetails.availableSeats = serviceData.availableSeats;
        localServiceDetails.fees = serviceData.fee;
        localServiceDetails.status = serviceData.status;
        handleDateChange(new Date(serviceData.date));
        // updateSelectedServiceTime(serviceData.sessionTime);

        const localImages = [];
        if (serviceData.images.length) {
            serviceData.images.forEach(each => {
                localImages.push(each.url);
            });
        }
        updateDescriptionImages([...localImages]);

        handleFromDateChange(getStartTime(serviceData.slots));
        handleToDateChange(getEndTime(serviceData.slots));

        changeMode("edit");
        if (window.innerWidth <= 768) {
            changeDisplayMode('add');
        }
        updateServiceDetails({ ...localServiceDetails });
    };

    const openRemove = (item, type) => {
        if (type === "Event") {
            if (checkCancelable(item)) {
                updateRemoveType(type);
                openRemoveModal(true);
                updateServiceId(item._id);
            }
        } else if (type === "Image") {
            updateRemoveType(type);
            openRemoveModal(true);
            updateImageIndex(item);
        }
    };

    const removeData = async () => {
        if (removeType === "Event") {
            console.log("selectedServiceIdToRemove ", selectedServiceIdToRemove);
            toggleDisablePage(true);
            const result = await removeEvent(selectedServiceIdToRemove);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.REMOVE_EVENT);
            updateServiceId("");
            openRemoveModal(false);
            if (mode === "edit") {
                resetServiceForm();
            } else {
                getAllUpcomingServices();
            }
            getAllPastServices();
        } else if (removeType === "Image") {
            const localImages = [...descriptionImages];
            localImages.splice(selectedImageIndexToRemove, 1);
            updateDescriptionImages([...localImages]);
            displayNavs(true);
            updateImageIndex(-1);
            openRemoveModal(false);
        }
    };

    const changeDate = (date) => {
        handleDateChange(new Date(date));
    };

    const openFileUploader = (imageId) => {
        if (descriptionImages.length === 10) {
            toast.error("Maximum 10 images can be uploaded");
            return;
        }
        if (document.getElementById(imageId)) {
            document.getElementById(imageId).click();
        }
    };

    const resetImage = () => {
        if (document.getElementById("galleryImage")) {
            document.getElementById("galleryImage").value = "";
        }
        updateGalleryImage(null);
    };

    const commonEventUploadFunction = async (imageFile) => {
        toggleDisablePage(true);
        const result = await uploadEventImage(imageFile);
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.EVENT_IMAGE_UPLOADED);
        console.log("result ", result)
        const localImages = [...descriptionImages];
        localImages.push(result.data.data);
        updateDescriptionImages([...localImages]);
        displayNavs(true);
        resetImage();
    };

    const uploadGalleryImage = async () => {
        if (!serviceDetails.title.trim().length) {
            toast.error("Please add event details to upload an image");
            return;
        }
        if (document.getElementById("galleryImage")) {
            let imageFile = document.getElementById("galleryImage");
            if (imageFile.files[0]) {
                
                if (imageFile.files[0].type.indexOf("jpeg") > -1
                 || imageFile.files[0].type.indexOf("png") > -1
                 || imageFile.files[0].type.indexOf("jpg") > -1) {
                    let img = new Image();
                    img.src = window.URL.createObjectURL(imageFile.files[0]);
                    console.log("imageFile.files[0] ", imageFile.files[0]);
                    img.onload = async () => {
                        if (img.width >= 1075 && img.height >= 440) {
                            updateGalleryImage(imageFile.files[0]);

                            if (imageFile.files[0].size <= maxImageSize) {
                                commonEventUploadFunction(imageFile.files[0]);
                            } else {
                                new Compressor(imageFile.files[0], {
                                    quality: compressionPercentage,
                                    width: compressionWidth,
                                    success: async (data) => {
                                        const newFile = new File([data], imageFile.files[0].name, { type: imageFile.files[0].type });
                                        await commonEventUploadFunction(newFile);
                                    },
                                    error: () => {
                                        toast.error("Error while uploading");
                                        resetImage();
                                    },
                                });
                            }
                            
                        } else {
                            toast.error("Please upload image with size greater than 1075x440px");
                            resetImage();
                            return;
                        }
                    }
                } else {
                    toast.error("Please upload .jpeg/.jpg/.png file");
                    resetImage();
                    return;
                }
            }
        }
    };

    const fromTimeChangeFunction = (event) => {
        handleFromDateChange(event);
        const fromIndex = (timeArr.indexOf(formatAMPM(event)) + 1);
        const toIndex = (timeArr.indexOf(formatAMPM(selectedToDate)));
        const localWorkingTimeSlots = [];

        if (fromIndex === toIndex) {
            localWorkingTimeSlots.push(fromIndex);
        } else {
            for (let i = fromIndex; i <= toIndex; i++) {
                localWorkingTimeSlots.push(i);
            }
        }

        if (localWorkingTimeSlots.length) {
            updateDateError();
        }
        console.log("localWorkingTimeSlots ", localWorkingTimeSlots)
    };

    const toTimeChangeFunction = (event) => {
        handleToDateChange(event);
        const fromIndex = (timeArr.indexOf(formatAMPM(selectedFromDate)) + 1);
        const toIndex = (timeArr.indexOf(formatAMPM(event)));
        const localWorkingTimeSlots = [];

        if (fromIndex === toIndex) {
            localWorkingTimeSlots.push(fromIndex);
        } else {
            for (let i = fromIndex; i <= toIndex; i++) {
                localWorkingTimeSlots.push(i);
            }
        }

        if (localWorkingTimeSlots.length) {
            updateDateError();
        }
    };

    const openJoinSessionModal = async (sessionData) => {
        if (!checkJoiningCallTime(sessionData)) {
            toast.error(CALL_TIME_PASSED);
            return;
        }
        updateSelectedEvent({ ...sessionData });
        openConfirmationModal(true);
        const resultData = await joinEventCall(sessionData._id);
        if (resultData.data.errorCode) {
            toast.error(API_ERROR[resultData.data.errorCode]);
            return;
        }
        if (resultData.data.data && resultData.data.data.errorCode) {
            toast.error(API_ERROR[resultData.data.data.errorCode]);
            return;
        }
    };

    const selectEventToOpenParticipant = async (eventData) => {
        toggleDisablePage(true);
        const servicesResult = await getEventParticipants(eventData._id);
        toggleDisablePage(false);
        if (servicesResult.data.errorCode) {
            toast.error(API_ERROR[servicesResult.data.errorCode]);
            return;
        }
        console.log("servicesResult ", servicesResult);
        if (servicesResult.data.data) {
            updateParticipants([...servicesResult.data.data]);
            toggleParticipantModal(true);
        }
    };

    const showReadMoreModal = (descText) => {
      toggleReadMoreModal(true);
      updateReadMoreDesc(descText);  
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={`row booking-details problem-area ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
                <RemoveModal
                    displayRemoveModal={displayRemoveModal}
                    openRemoveModal={openRemoveModal}
                    removeType={removeType}
                    removeData={removeData} />
                <JoinEventCallConfirmationModal
                    displayConfirmationModal={displayConfirmationModal}
                    openConfirmationModal={openConfirmationModal}
                    selectedEvent={selectedEvent}
                    selectedProfile={selectedProfile} />
                {openParticipantModal ? <ParticipantsModal
                    toggleModal={toggleParticipantModal}
                    displayModal={openParticipantModal}
                    participantList={participants}
                /> : null}
                {openReadMoreModal ? <ReadMoreModal
                    toggleModal={toggleReadMoreModal}
                    displayModal={openReadMoreModal}
                    description={readMoreDesc}
                /> : null}
                <div className="col-md-7 col-xs-12 p-0">
                    <div className="row m-0">
                        {displayMode === 'list' ? <>
                            <div className="col-md-6 col-xs-12 categories-heading">
                                <h4 className={`mb-0`}>Event Manager <i className="fas fa-info-circle ml-3" aria-hidden="true" style={{ fontSize: "18px", cursor: "pointer" }} /></h4>
                            </div>
                            <div className="col-md-6 col-xs-12 pl-0 upcoming-past-button-wrapper text-right pt-3 mb-3">
                                <button onClick={() => updateCurrentTab("upcoming")} className={`btn btn-primary services-sessions-button ${currentTab === 'upcoming' ? 'active' : ''}`}>Upcoming</button>
                                <button onClick={() => updateCurrentTab("past")} className={`btn btn-primary services-sessions-button ${currentTab === 'past' ? 'active' : ''}`}>Past</button>
                                <button type="button" className="btn btn-outline-primary btn-sm less-than-iniline-block-768" onClick={() => changeDisplayMode('add')}>Add Event</button>
                            </div>
                        </> : null}
                        <div className={`col-12 ${displayMode === 'add' ? 'display-none' : ''}`}>
                            <div className="row" style={{ margin: 0 }}>
                                {currentTab === 'upcoming' ?
                                    sessionsList.length ?
                                        sessionsList.map((service, index) => {
                                            return <div className={`col-12 professional-wrapper service-wrapper
                                            `} key={index}>
                                                <div className={`row ${index === sessionsList.length - 1 ? 'pb-20' : ''} ${index === 0 ? 'bor-rad-top' : ''} ${index === sessionsList.length - 1 ? 'bor-rad-bot' : ''}`} style={{ margin: 0 }}>
                                                    <div className="col-12">
                                                        <div className="service-list-name pr-3">{service.title}</div>
                                                        {service.availableSeats === service.numberOfSeats && service.isCancalable ?
                                                            <img className="remove-session-image" src={require("../../../images/remove.svg")} alt="remove service" onClick={() => openRemove(service, "Event")} /> : null}
                                                    </div>
                                                    <div className="col-12" style={{ margin: "5px 0 0" }}>
                                                        <div className="service-description">{service.description.length > 150 ? 
                                                        <span>{service.description.slice(0, 150)}... <button className='btn btn-link'>Read More</button></span> : service.description}</div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="profile-date-time row ml-0 mr-0 pt-2 pl-0">
                                                            <div className="col-sm-6 col-xs-12 pl-0">
                                                                <div className="label">Date</div>
                                                                <div>
                                                                    {getDisplayedFullDateFormat(new Date(service.date))}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col-xs-12 pl-0 time-slot-less-575">
                                                                <div className="label">Time</div>
                                                                <div>
                                                                    {getDuration(service.slots)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 col-12 p-0">
                                                        <div className="fee-text seat-count bor-right mt-3">Total Seats: {service.numberOfSeats}</div>
                                                        <div className="fee-text seat-count mt-3">Available Seats: {service.availableSeats}</div>
                                                    </div>
                                                    <div className="w-100 col-12 p-0">
                                                        <div className="fee-text mt-3">Service Fee: <i className="fas fa-rupee-sign"></i>{service.fee.toFixed(2)}</div>
                                                        {service.isEditable ?
                                                            <>
                                                                <button type="button" className="btn btn-primary small-book-now-button edit-session-button float-right mt-2" onClick={() => chooseService(service)}>Edit -{'>'}</button>
                                                                <div className="seat-count mt-2 text-right">Status: {SessionConfig[service.status]}</div>
                                                            </> : null}

                                                    </div>
                                                    {service.status === SessionConfigNumber.Confirmed ?
                                                    <div className={`mt-2 w-100 col-12 p-0 ${window.innerWidth < 768 ? 'text-center' : 'text-right'}`}>
                                                        {service.numberOfSeats !== service.availableSeats ?
                                                            <button className="btn btn-outline-primary details-button" style={{ padding: "9px 15px" }} onClick={() => selectEventToOpenParticipant(service)}>View Participants</button> : null}
                                                        <button className="btn btn-primary details-button ml-2" disabled={!service.isCallJoinable} onClick={() => openJoinSessionModal(service)}>Join</button>

                                                    </div> : null}
                                                    {index !== sessionsList.length - 1 ?
                                                        <hr style={{ width: "100%", margin: "20px 0 0" }} /> : null}
                                                </div>
                                            </div>
                                        })
                                        : <div className="text-center well col-12 color-black">
                                            No Upcoming Events Available!
                                        </div> :
                                    pastSessionsList.length ?
                                        pastSessionsList.map((service, index) => {
                                            return <div className={`col-12 professional-wrapper service-wrapper
                                            `} key={index}>
                                                <div className={`row ${index === pastSessionsList.length - 1 ? 'pb-20' : ''} ${index === 0 ? 'bor-rad-top' : ''} ${index === pastSessionsList.length - 1 ? 'bor-rad-bot' : ''}`} style={{ margin: 0 }}>
                                                    <div className="col-12">
                                                        <div className="service-list-name pr-3">{service.title}</div>
                                                    </div>
                                                    <div className="col-12" style={{ margin: "5px 0 0" }}>
                                                    <div className="service-description">{service.description.length > 150 ? 
                                                        <span>{service.description.slice(0, 150)}... <button className='btn btn-link' onClick={() => showReadMoreModal(service.description)}>Read More</button></span> : service.description}</div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="profile-date-time row ml-0 mr-0 pt-2 pl-0">
                                                            <div className="col-sm-6 col-xs-12 pl-0">
                                                                <div className="label">Date</div>
                                                                <div>
                                                                    {getDisplayedFullDateFormat(new Date(service.date))}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col-xs-12 pl-0 time-slot-less-575">
                                                                <div className="label">Time</div>
                                                                <div>
                                                                    {getDuration(service.slots)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 col-12 p-0">
                                                        <div className="fee-text seat-count bor-right mt-3">Total Seats: {service.numberOfSeats}</div>
                                                        <div className="fee-text seat-count mt-3">Available Seats: {service.availableSeats}</div>
                                                    </div>
                                                    <div className="w-100 col-12 p-0">
                                                    <div className="fee-text mt-3" style={{
                                                        color: `${service.status === 4 ? '#67ab94' : '#b36569'}`
                                                    }}>Status: {SessionConfig[service.status]}</div>
                                                    </div>
                                                    <div className="w-100 col-12 p-0">
                                                        <div className="fee-text mt-3">Service Fee: <i className="fas fa-rupee-sign"></i>{service.fee.toFixed(2)}</div>
                                                        {window.innerWidth >= 768 ?
                                                        <button className="btn btn-outline-primary details-button float-right" style={{ padding: "9px 15px" }} onClick={() => selectEventToOpenParticipant(service)}>View Participants</button> : null}
                                                    </div>
                                                    {service.numberOfSeats !== service.availableSeats && window.innerWidth < 768 ?
                                                    <div className={`w-100 col-12 p-0 ${window.innerWidth < 768 ? 'text-center' : 'text-right'}`}>
                                                            <button className="btn btn-outline-primary details-button" style={{ padding: "9px 15px" }} onClick={() => selectEventToOpenParticipant(service)}>View Participants</button> 
                                                        </div> : null}
                                                    {/* {service.status === SessionConfigNumber.Rejected ||
                                                        service.status === SessionConfigNumber.AutoRejected ||
                                                        service.status === SessionConfigNumber.CancelledByVendor ?
                                                        <div className="col-12 booking-details-header text-center mt-3 bor-bottom-0">
                                                            The event has been {service.status === SessionConfigNumber.Rejected ? 'rejected by admin' : ''}
                                                            {service.status === SessionConfigNumber.AutoRejected ? 'rejected by admin' : ''}
                                                            {service.status === SessionConfigNumber.CancelledByVendor ? 'cancelled by you' : ''}
                                                        </div> : null} */}
                                                    {index !== pastSessionsList.length - 1 ?
                                                        <hr style={{ width: "100%", margin: "20px 0 0" }} /> : null}
                                                </div>
                                            </div>
                                        })
                                        : <div className="text-center well col-12 color-black">
                                            No Past Events Available!
                                        </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`col-md-5 col-xs-12 p-0 ${displayMode === 'list' ? 'more-than-768' : 'less-than-768'}`}>
                    <div className="col-12 categories-heading">
                        <h4 className={`mb-0`}>Add/ Edit Event</h4>
                    </div>
                    <div className="col-12">
                        <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                            <div className="col-12 p-0 mb-3 add-edit-service-button-wrapper">
                                {mode === "edit" ?
                                    <button type="button" className="btn btn-primary btn-sm" onClick={saveService}>Save</button> : null}
                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={resetServiceForm}>Reset</button>
                            </div>
                            <div className="col-12 p-0 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Event Name</label>
                                <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="fa fa-user" /></div>
                                </div>
                                    <input type="text" className={`form-control ${serviceDetailsError.title ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Event Name" value={serviceDetails.title} onChange={e => changeServiceDetails(e.target.value, "title")} />
                                </div>
                                {serviceDetailsError.title ?
                                    <div className="input-error">{serviceDetailsError.title}</div> : null}
                            </div>
                            <div className="col-6 pl-0 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Price</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="fas fa-rupee-sign" /></div>
                                    </div>
                                <input type="text" className={`form-control ${serviceDetailsError.fees ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Price (in Rs)" value={serviceDetails.fees} onChange={e => changeServiceDetails(e.target.value.replace(/\D/, ''), "fees")} disabled={mode === "edit" && serviceDetails.status === SessionConfigNumber.ApprovalPending} />
                                </div>
                                {serviceDetailsError.fees ?
                                    <div className="input-error">{serviceDetailsError.fees}</div> : null}
                            </div>
                            <div className="col-6 pr-0 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">No of Seats (max 100)</label>
                                <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="fa fa-user" /></div>
                                </div>
                                <input type="text" className={`form-control ${serviceDetailsError.noOfSeats ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="No of Seats" value={serviceDetails.noOfSeats} onChange={e => changeServiceDetails(e.target.value.replace(/\D/, ''), "noOfSeats")} disabled={mode === "edit" && serviceDetails.status === SessionConfigNumber.ApprovalPending} />
                                </div>
                                {serviceDetailsError.noOfSeats ?
                                    <div className="input-error">{serviceDetailsError.noOfSeats}</div> : null}
                            </div>
                            <div className="col-12 pl-0 pr-0 mb-3 custom-date-picker-design">
                                <label htmlFor="exampleFormControlInput1" className="form-label display-block">Select Date</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="far fa-hourglass" /></div>
                                    </div>
                                <DatePicker value={selectedDate} onChange={changeDate} className="time-picker-with-icon" format="dd MMM, yyyy" minDate={initialSessionDate} disabled={mode === "edit" && serviceDetails.status === SessionConfigNumber.ApprovalPending} />
                                </div>
                            </div>
                            <div className="col-6 pl-0 mb-3 custom-time-picker-design">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Start Time</label><br />
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="far fa-hourglass" /></div>
                                    </div>
                                    <TimePicker value={selectedFromDate} id="fromTime" className={`${orgServiceDetailsError.selectedFromDate ? 'timepicker-error-border' : ''} time-picker-with-icon`} onChange={(e) => fromTimeChangeFunction(e)} minutesStep={30} disabled={mode === "edit" && serviceDetails.status === SessionConfigNumber.ApprovalPending} />
                                </div>
                                {orgServiceDetailsError.selectedFromDate ?
                                    <div className="input-error">{orgServiceDetailsError.selectedFromDate}</div> : null}
                            </div>
                            <div className="col-6 pr-0 mb-3 custom-time-picker-design">
                                <label htmlFor="exampleFormControlInput1" name="toTime" className="form-label">End Time</label><br />
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="far fa-hourglass" /></div>
                                    </div>
                                <TimePicker value={selectedToDate} className={`${orgServiceDetailsError.selectedFromDate ? 'timepicker-error-border' : ''} time-picker-with-icon`} onChange={(e) => toTimeChangeFunction(e)} minutesStep={30} disabled={mode === "edit" && serviceDetails.status === SessionConfigNumber.ApprovalPending} />
                                </div>
                            </div>
                            <div className="col-12 p-0 mb-3">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label">Description</label>
                                <textarea className={`form-control ${serviceDetailsError.description ? 'error-border' : ''}`} id="exampleFormControlTextarea1" rows="3" placeholder="Write short description about the event..." value={serviceDetails.description} onChange={e => changeServiceDetails(e.target.value, "description")}></textarea>
                                {serviceDetailsError.description ?
                                    <div className="input-error">{serviceDetailsError.description}</div> : null}
                            </div>
                            <div className="col-12 p-0">
                                <div className="row" style={{ margin: "0" }}>
                                    <div className="col-12 p-0">
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className={`col-sm-6 col-xs-12 pl-0 pt-2 ${descriptionImages && descriptionImages.length ? 'mt-2' : ''}`}>
                                                <h5 className={`mb-0`}>Images <br /> <span style={{
                                                    fontSize: '11px',
                                                    color: '#566986',
                                                    fontWeight: 400
                                                }}>size more than 1075x440px</span></h5>
                                            </div>
                                            {!uploadedGalleryImage && descriptionImages && descriptionImages.length ?
                                                <div className="col-sm-6 col-xs-12 pr-0 text-right category-arrows" onMouseEnter={() => { displayNavs(true) }}>
                                                    <button className="event-prev-button-wrapper" onClick={prevClick} id="slideprev" disabled={!showPrevButtons}>
                                                        <i className="fas fa-arrow-left"></i>
                                                    </button>
                                                    <button className="event-next-button-wrapper" onClick={nextClick} id="slide" disabled={!showNextButtons}>
                                                        <i className="fas fa-arrow-right"></i>
                                                    </button>
                                                </div> : null}
                                        </div>
                                    </div>
                                    {!uploadedGalleryImage ?
                                        <div className={`about-image-wrapper pl-0 pt-2`}>
                                            <input type="file" accept="image/*" id="galleryImage" hidden onChange={uploadGalleryImage} />
                                            <div className={`profile-image description-image`} style={{ backgroundColor: "#eee", backgroundImage: "url(" + require("../../../images/plus.svg") + ")" }} onClick={() => openFileUploader("galleryImage")} />
                                        </div> :
                                        <div className={`about-image-wrapper pl-0 pt-2`}>
                                            <input type="file" accept="image/*" id="galleryImage" hidden onChange={uploadGalleryImage} />
                                            <div className="profile-image description-image" style={{ backgroundColor: "#eee", backgroundImage: "url(" + URL.createObjectURL(uploadedGalleryImage) + ")" }} />
                                        </div>
                                    }
                                    <div className={`categories-wrapper gallery-images-wrapper pl-0 pr-0 pt-2 pb-0`} style={{ margin: 0 }}>
                                        {descriptionImages && descriptionImages.length ?
                                            descriptionImages.map((image, index) => {
                                                return <div className={`about-image-wrapper pl-0`} key={index}>
                                                    <div className="profile-image description-image" style={{ backgroundImage: "url(" + image + ")" }} />
                                                    <img src={require("../../../images/remove.svg")} onClick={e => { e.stopPropagation(); openRemove(index, "Image") }} alt={`remove gallery`} />
                                                </div>
                                            })
                                            : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-0 text-center mt-2">
                                <button type="button" className="btn btn-primary btn-sm" onClick={payNow}>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MuiPickersUtilsProvider>
    )
}

export default Service;